import React, { useState, useEffect, useCallback } from "react";
import Echo from 'laravel-echo'
import axios from 'axios'
import Robot from './image/venvoirRobot.png'

import { AXIOS } from "./services/Base";

export default function App() {

  const [inputData, setInputData] = useState('');
  const [nextPage, setNextPage] = useState('')
  const [loadingMoreMessages, setLoadingMoreMessages] = useState(false)

  const [ displayChat, setDisplayChat ] = useState(false)
  const [ gettingMessages, setGettingMessages] = useState(true)
  const [ chatMessages, setChatMessages ] = useState()
  const [ uniqueId, setUniqueId ] = useState('')
  const [ firstTimeChatting, setFirstTimeChatting ] = useState(false)
  const [ name, setName ] = useState('')
  const [ email, setEmail ] = useState('')

  const getMessages = useCallback(() => {

    AXIOS().get(`${uniqueId}/messages`)
    .then((response) => {
      setChatMessages(response.data.data)
      setGettingMessages(false)
      setNextPage(response.data.next_page_url)
    })
  
  }, [uniqueId])

  const scrollToBottom = () => {

    if(displayChat && !firstTimeChatting) {

      var messageBody = document.querySelector('#messageBody');
      messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;

    }

  }

  useEffect(() => {

    if(uniqueId) {

      window.Echo = new Echo({
        broadcaster: 'pusher',
        key: 'PUSHER_KEY_VENVOIR_X9082190328901123210983921032L',
        wsHost: 'server.venvoir.com',
        wsPort: 2053,
        wssPort: 2053,
        forceTLS: true,
        disableStats: true,
        enabledTransports: ['ws', 'wss'],
        authorizer: (channel, options) => {
            return {
                authorize: (socketId, callback) => {
                    axios.post('https://server.venvoir.com/api/v1/broadcasting/auth', {
                        socket_id: socketId,
                        channel_name: channel.name,
                        user: 5,
                    }).then(response => {
                        callback(false, response.data);
                    }).catch(error => {
                        callback(true, error);
                    });
                }
            };
        },
      });
      
      

      setTimeout(() => {
    
        window.Echo.private(`message-support.${uniqueId}`).listen('.App\\Events\\Broadcast\\BroadcastMessageEvent', (event) => {

          
          
          getMessages()
      
         });
        
      }, 10000);
      
    }
    
  }, [uniqueId, getMessages])

  useEffect(scrollToBottom, [displayChat, firstTimeChatting]);
  
  const sendMessage = async () => {

    const setNewMessage = [...chatMessages]
    const messageToSend = inputData

    for(var i = 0; i < setNewMessage.length; i += 1) {
      if(setNewMessage[i].from_panel === 0) {            
        var image = setNewMessage[i].display.avatar
      }
    }

    const now = new Date()

    const newMessage = {
      id: chatMessages[0]?.id ? chatMessages[0].id + 1 : 0,
      from_panel: 0,
      display: {
        message: inputData,
        avatar: image,
      },
      sending: true,
      created_at: now
    }

    const updatedMessages = [newMessage].concat(setNewMessage) // [ 4, 3, 2, 1 ]

    setChatMessages(updatedMessages)
    setInputData('')    
    scrollToBottom()

    await AXIOS().post(`${uniqueId}`, {
      is_platform_support: true,
      message: messageToSend,
      name: name ? name : 'Visitante',
      email: email
    })  
    .then((response) => {
      const updateMessageStatus = [...updatedMessages]
      for(var i = 0; i < updateMessageStatus.length; i += 1) {
        if(updateMessageStatus[i].created_at === now) {         
          updateMessageStatus[i].created_at = response.data.created_at;   
          updateMessageStatus[i].sending = false;   
          updateMessageStatus[i].id = response.data.id;
          updateMessageStatus[i].display.avatar = response.data.avatarUrl;
        }
      }
      setChatMessages(updateMessageStatus)
    })

  }

  const loadMore = () => {

    setLoadingMoreMessages(true)

    AXIOS().get(`${nextPage}`)
    .then((response) => {
      const setMessages = [...chatMessages]
      setMessages.push(...response.data.data)
      setChatMessages(setMessages)
      setLoadingMoreMessages(false)
      setNextPage(response.data.next_page_url)
    })

  }

  useEffect(() => {

    //localStorage.setItem('random@chatId', 'random-120903asdasdsax')

    if(localStorage.getItem('chat@userEmail') || localStorage.getItem('chat@userName')) {

      setName(localStorage.getItem('chat@userName'))
      setEmail(localStorage.getItem('chat@userEmail'))

    }

    if(localStorage.getItem('random@chatId')) {

      var chatUniqueId = localStorage.getItem('random@chatId')

      setUniqueId(chatUniqueId)

    } else {

      setFirstTimeChatting(true)
      
      var text = "";
      var charset = "abcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < charset.length; i++) {
          text += charset.charAt(Math.floor(Math.random() * charset.length));
      }

      chatUniqueId = text

      localStorage.setItem('random@chatId', text)

      setUniqueId(text)

    }

    getMessages()

  }, [getMessages])

  const onEnterPress = (e) => {

    if(e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      sendMessage()
    }

  }
  
  const dateForm = (date) => {

    var data = new Date(`${date}`)
    var dia  = data.getDate().toString()
    var diaF = (dia.length === 1) ? '0'+dia : dia
    var mes  = (data.getMonth()+1).toString()
    var mesF = (mes.length === 1) ? '0'+mes : mes
    var anoF = data.getFullYear()
    var datetex = data.toTimeString();
    var datetext = datetex.split(' ')[0];
    return `${diaF}/${mesF}/${anoF} às ${datetext}`

  }

  const getRobotDate = () => {

  if(localStorage.getItem('chat@robotTime')) {
    
    return(dateForm(localStorage.getItem('chat@robotTime')));

  } else {

    var date = new Date();
    date = date.getUTCFullYear() + '-' +
    ('00' + (date.getUTCMonth()+1)).slice(-2) + '-' +
    ('00' + date.getUTCDate()).slice(-2) + ' ' + 
    ('00' + date.getUTCHours()).slice(-2) + ':' + 
    ('00' + date.getUTCMinutes()).slice(-2) + ':' + 
    ('00' + date.getUTCSeconds()).slice(-2);
    localStorage.setItem('chat@robotTime', date)    
    return(dateForm(date));

  }


  }

  const carregaMaisMensagens = (e) => {    

    var messageBody = document.querySelector('#messageBody');

    const bottom = Math.floor((e.target.scrollHeight - e.target.scrollTop) + 5 > messageBody.scrollHeight);

    if(bottom && nextPage) {

      loadMore()

    }
  
  }

  const showChat = (e) => {
  
    e.preventDefault()

    if(email && name) {

      localStorage.setItem('chat@userEmail', email)

      localStorage.setItem('chat@userName', name)
  
      setFirstTimeChatting(false)

    }

  }

  const checkEmail = (text) => {

      const usuario = text.substring(0, text.indexOf("@"));
      const dominio = text.substring(text.indexOf("@")+ 1, text.length);
       
      if (
        (usuario.length >=1) &&
        (dominio.length >=3) && 
        (usuario.search("@")===-1) && 
        (dominio.search("@")===-1) &&
        (usuario.search(" ")===-1) && 
        (dominio.search(" ")===-1) &&
        (dominio.search(".")!==-1) &&      
        (dominio.indexOf(".") >=1)&& 
        (dominio.lastIndexOf(".") < dominio.length - 1)) {
        return true
      }
      else{
        return false
      }

  }

  const startChatHandle = name && email && checkEmail(email)

  return (

    <>

      {

        !displayChat ?

          !gettingMessages &&

            <div onClick={() => setDisplayChat(!displayChat)} className="box chatTrigger is-flex is-justify-content-center is-align-items-center">
        
              <span><i className="bi bi-chat has-text-white" style={{fontSize: '1.5rem'}}></i></span>

              <span className="ml-2 has-text-white">Fale conosco</span>

              {/*<div className="unreadCounter has-text-white">2</div>*/}
        
            </div>

        :

          firstTimeChatting ?

            <div className="box supportChatGetInfo">
        
              <div className="block is-marginless suppportChatHeader is-flex is-justify-content-space-between">
        
                <div>
        
                  <p><strong className="has-text-white">Alguma dúvida?</strong></p>
        
                  <p>Fale conosco rapidamente</p>
        
                </div>
        
                <div>
        
                  <p onClick={() => setDisplayChat(!displayChat)}><strong className="closeBox has-text-white"><i className="bi bi-x" style={{fontSize: '2rem'}}></i></strong></p>
        
                </div>
        
              </div>

              <div className="block collectInfo px-4 py-2">

                <div className="mb-2">

                  <span>Antes de começarmos, que tal nos dizer quem é você?</span>

                </div>

                <form onSubmit={(e) => showChat(e)}>
                  
                  <div class="field">
                    <label class="label">Nome</label>
                    <div class="control">
                      <input class="input" type="text" placeholder="Veronica Voir" onChange={(e) => setName(e.target.value)} value={name} />
                    </div>
                  </div>

                  <div class="field">
                    <label class="label">Email</label>
                    <div class="control">
                      <input class={`input ${!checkEmail(email) && email ? 'is-danger' : ''}`} type="email" placeholder="veronica.voir@venvoir.com" onChange={(e) => setEmail(e.target.value)} value={email} />
                    </div>

                    {

                      !checkEmail(email) && email &&

                        <p class="help is-danger">Email inválido</p>

                    }

                  </div>

                  <div className="buttons">

                    {

                      startChatHandle ?

                        <button className="button startChat ml-0 has-text-white">Iniciar chat</button>

                      :

                        <button disabled className="button startChat ml-0 has-text-white">Iniciar chat</button>

                    }

                    <div className="undefinedUser has-text-underlined is-clickable" onClick={() => setFirstTimeChatting(false)}>

                      Não desejo informar

                    </div>

                  </div>

                </form>

              </div>
              
            </div>

          :

            <div className="box supportChatContainer">
        
              <div className="block is-marginless suppportChatHeader is-flex is-justify-content-space-between">
        
                <div>
        
                  <p><strong className="has-text-white">Alguma dúvida?</strong></p>
        
                  <p>Fale conosco rapidamente</p>
        
                </div>
        
                <div>
        
                  <p onClick={() => setDisplayChat(!displayChat)}><strong className="closeBox has-text-white"><i className="bi bi-x" style={{fontSize: '2rem'}}></i></strong></p>
        
                </div>
        
              </div>
        
              <div className="content supportChatContent">
        
                <div id='messageBody' className="block supportMessages" onScroll={(e) => carregaMaisMensagens(e)}>

                  <div className="block messagesArea is-flex is-flex-direction-column is-justify-content-flex-end">

                    {loadingMoreMessages && <span className="is-flex is-justify-content-center mb-4"><i className="bi bi-three-dots"></i></span>}

                    {

                      name && email && !nextPage &&
          
                        <div className={`chatMessageContainer mb-4 is-flex admin user is-justify-content-flex-end'}`}>
          
                          <div>
          
                            <div className={`box supporChattMessage admin user mb-2`}>

                              <div>Olá, {name}, como podemos te ajudar?</div>
            
                              <div className="block messageInfo mt-2">
            
                                <p className="messageSent mb-0">{getRobotDate()}</p>
            
                                <p className="messageStatus">

                                  <strong className="has-text-white">
                                  
                                    Venvoir robot

                                  </strong>
            
                                </p>
            
                              </div>
                              
                            </div>            
          
                          </div>

                          <figure className={`image chatUserImage admin`}>
                            <img alt="user" className="is-rounded" src={Robot} />
                          </figure>
          
                        </div>

                    }
        
                    {chatMessages && chatMessages.map((message) => {
          
                      return (
          
                        <div key={message.id} className={`chatMessageContainer mb-4 is-flex ${message.from_panel ? 'admin' : 'user is-justify-content-flex-end'}`}>
          
                          <div>
          
                            <div className={`box supporChattMessage ${message.from_panel ? 'admin' : 'user mb-2'}`}>

                              <div dangerouslySetInnerHTML={{__html: message.display.message}} />
            
                              <div className="block messageInfo mt-2">
            
                                <p className="messageSent mb-0">{dateForm(message.created_at)}</p>
            
                                <p className="messageStatus">

                                  <strong className="has-text-white">
                                  
                                    {
                                    
                                      message.from_panel ? message.display.name : ''
                                      
                                    }

                                  </strong>
            
                                </p>
            
                              </div>
                              
                            </div>
            
                            {
            
                              !message.from_panel ? 
                              
                                  <div className="block is-flex is-align-items-center is-justify-content-flex-end messageInfo has-text-right mb-2">
                                    
                                    {
                                    
                                      message.sending ? 
                                      
                                        <><span><i className="far fa-clock"></i></span><span> enviando...</span></> 
                                        
                                      : 
                                      
                                        <><span><i className="bi bi-check" style={{fontSize: '15px'}}></i></span><span> enviado</span></>
                                        
                                    }
                                    
                                  </div> 
                                  
                              : 
                              
                                ''
            
                            }                 
          
                          </div>

                          <figure className={`image chatUserImage ${message.from_panel ? 'admin' : ''}`}>
                            <img alt="user" className="is-rounded" src={`${message.display.avatar ? message.display.avatar : "https://bulma.io/images/placeholders/128x128.png"}`} />
                          </figure>
          
                        </div>
          
                      )
          
                    }).reverse()}

                  </div>
        
                </div>
        
                <div className="block supportInteraction is-flex is-align-items-center is-justify-content-space-between">
        
                  <form className="is-marginless" onSubmit={() => sendMessage()}>
        
                    <textarea onKeyDown={(e) => onEnterPress(e)} className="messageArea" placeholder="Digite sua message aqui..." value={inputData} onChange={(e) => setInputData(e.target.value)} />
        
                  </form>
        
                  <div className="buttons">
        
                    <div className={`button sendButton ${inputData ? '' : 'is-hidden'}`} onClick={inputData ? () => sendMessage() : null}>
        
                      <i className="bi bi-cursor has-text-white"></i>
        
                    </div>
        
                  </div>
        
                </div>
        
              </div>
        
            </div>

      }

    </>

  );

}