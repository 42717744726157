import axios from 'axios';

export const URL_API = `https://server.venvoir.com/api/v1/support/website/messages-sessions/`;


export function AXIOS(){
        
        return axios.create({

                baseURL: URL_API,
                /*
                        headers: {
                                "Authorization" : "Bearer " + sessionStorage.getItem('access_token'),
                                "Accept" : 'application/json',
                                "Content-Type" : 'application/json'
                        }
                */

        });
        
}

export function getAxiosSource(){
        let axiosSource = axios.CancelToken.source()
        axiosSource.token.promise.then = axiosSource.token.promise.then.bind(axiosSource.token.promise);
        axiosSource.token.promise.catch = axiosSource.token.promise.catch.bind(axiosSource.token.promise);
        return axiosSource;
}

export default axios;